import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  container: { padding: '18%' },
  successful: {
    width: '63px',
    height: '63px',
    borderRadius: '50%',
    margin: '0 auto 20px',
    boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.16)',
    background: '#fe5a35',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    '&:after': {
      content: "''",
      width: '10px',
      height: '28px',
      border: 'solid white',
      borderWidth: '0px 6px 6px 0',
      transform: 'rotate(45deg)',
      marginBottom: '5px',
    },
  },
  [theme.breakpoints.up('sm')]: {
    successful: {
      width: '89px',
      height: '89px',
      marginBottom: '23px',
      '&:after': {
        width: '15px',
        height: '35px',
        borderWidth: '0px 8px 8px 0',
        marginBottom: '7px',
      },
    },
  },
  [theme.breakpoints.up('md')]: {
    successful: {
      width: '98px',
      height: '98px',
      marginBottom: '23px',
      '&:after': {
        width: '18px',
        height: '46px',
        borderWidth: '0px 8px 8px 0',
        marginBottom: '7px',
      },
    },
  },
}));
export default style;
