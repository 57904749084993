import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  iPopup: {
    position: 'fixed',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    zIndex: 100,
    overflowY: 'auto',
  },
  popupOverlay: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    background: 'rgb(57, 54, 79, 0.8)',
    alignItems: 'center',
    justifyItems: 'center',
    zIndex: 120,
    overflowY: 'auto',
  },
  loginContainer: {
    maxWidth: '460px',
    borderRadius: '4px',
    boxShadow: '0 20px 40px 0 rgba(255, 90, 54, 0.16)',
    border: 'solid 1px #c7c6cb',
    backgroundColor: '#ffffff',
    width: '92%',
    margin: 'auto',
    position: 'relative',
    zIndex: 150,
  },
  overlayLoader: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    background: 'rgba(255, 255, 255, 0.85)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginBox: { maxWidth: '380px', padding: '30px', margin: 'auto' },
  close: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    color: 'darkgrey',
    cursor: 'pointer',
  },
}));

export default style;
