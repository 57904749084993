import { Typography } from '@mui/material';
import React from 'react';

import style from './style';

const Successful = () => {
  const classes = style();

  return (
    <div className={classes.container}>
      <div className={classes.successful}></div>
      <Typography variant="h4" align="center" paragraph>
        Successful
      </Typography>
      <div className={classes.body}>
        <Typography variant="body1" align="center">
          we have sent a password reset verification link to your email id,
          please check your inbox
        </Typography>
      </div>
    </div>
  );
};

export default Successful;
