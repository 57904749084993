import React from 'react';
import { Typography, TextField, Box } from '@mui/material';

import PrimaryButton from '../../../MaterialUi/PrimaryButton';

const ConfirmEmail = ({ classes, email, changeData, emailFormSubmit }) => {
  return (
    <div className={classes.loginBox}>
      <div className={classes.header}>
        <Typography variant="h5" paragraph align="center">
          Enter your registered email address
        </Typography>
      </div>

      <form onSubmit={(e) => emailFormSubmit(e)}>
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          margin="normal"
          name="email"
          id="email"
          fullWidth
          placeholder="Registered Email"
          onChange={(e) => changeData(e)}
          value={email}
        />

        <Box mt={3}>
          <PrimaryButton type="submit" size="large" fullWidth>
            Confirm
          </PrimaryButton>
        </Box>
      </form>
    </div>
  );
};

export default ConfirmEmail;
