import { useRef } from 'react';
import { useScript } from '../../hooks';

const GoogleLogin = ({ onSuccess, onError }) => {
  /**
   * https://github.com/anthonyjgrove/react-google-login/issues/502#issuecomment-1113081909
   */
  const googleSignInButton = useRef(null);

  useScript('https://accounts.google.com/gsi/client', () => {
    window.google.accounts.id.initialize({
      client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
      callback: onSuccess,
      onError: onError,
    });
    window.google.accounts.id.renderButton(
      googleSignInButton.current,
      { theme: 'outline', size: 'large' }, // customization attributes
    );
  });

  return <div ref={googleSignInButton} />;
};

export default GoogleLogin;
