import { deviceSlice } from '../reducers/device';

export const setDeviceWidth = (width) => async (dispatch) => {
  dispatch(deviceSlice.actions.setScreenWidth({ width }));
  dispatch(setDeviceData());
};

const covertCurency = async (from = 'USD', to = 'INR') => {
  try {
    const exchangeRate = localStorage?.getItem(`${from}ExchangeRateTo${to}`);

    const date = new Date();

    if (exchangeRate && date < new Date(JSON.parse(exchangeRate).expiry)) {
      return JSON.parse(exchangeRate).val;
    } else {
      // Add a day
      date.setDate(date.getDate() + 1);

      const res = await fetch(
        'https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/' +
          from.toLowerCase() +
          '/' +
          to.toLowerCase() +
          '.json',
      );

      let currency = await res.text();
      currency = JSON.parse(currency);

      const data = {
        val: +currency?.[to.toLowerCase()]?.toFixed(2),
        expiry: date,
      };

      localStorage?.setItem(`${from}ExchangeRateTo${to}`, JSON.stringify(data));

      return currency?.[to.toLowerCase()]?.toFixed(2) || 1;
    }
  } catch (error) {
    console.log(error);
  }
};

export const setDeviceData = () => async (dispatch) => {
  let userLocation = { country: null, status: null, country_name: null };
  if (localStorage?.getItem('country')) {
    userLocation.country = localStorage.getItem('country');
  } else {
    let countryName = 'other';
    try {
      const res = await fetch('https://extreme-ip-lookup.com/json');
      userLocation = await res.json();
      if (userLocation.status && userLocation.status !== 'fail') {
        countryName = userLocation?.country
          ? userLocation.country
          : countryName;
      }
    } catch (e) {
      console.log(e);
    }

    if (countryName === 'other') {
      try {
        const res = await fetch('https://geolocation-db.com/json/');
        userLocation = await res.json();
        countryName = userLocation?.country_name
          ? userLocation.country_name
          : countryName;
      } catch (e) {
        console.log(e);
      }
    }

    localStorage.setItem('country', countryName);
  }

  const isIndia = userLocation?.country?.toLowerCase() === 'india';

  const conversionRate = await covertCurency();

  const userdata = {
    country: userLocation?.country,
    currency: isIndia ? 'INR' : 'USD',
    currVal: isIndia ? +conversionRate : 1,
    usedToInr: +conversionRate,
  };

  dispatch(deviceSlice.actions.setScreenData({ ...userdata }));
};
