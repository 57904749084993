import React, { useState } from 'react';
import { Close } from '@mui/icons-material';
import { CircularProgress, Typography } from '@mui/material';

import ConfirmEmail from './ConfirmEmail';
import Successful from './Successful';

import { removePopup } from '../../../../actions/popup';

import style from './style';
import { passwordReset } from '../../../../client/OneistoxApiClient';
import { useAppDispatch } from '../../../../hooks';

const ForgotPasswordPopup = ({}) => {
  const dispatch = useAppDispatch();
  const classes = style();

  const [formData, setFormData] = useState({
    email: '',
  });

  const [formProcess, setFormProcess] = useState({
    isLoading: false,
    isSuccessful: false,
  });

  const [hintErrors, setHintErrors] = useState({
    text: '',
    isError: false,
  });

  const { email } = formData;

  const { isLoading, isSuccessful } = formProcess;

  const emailFormSubmit = async (e) => {
    e.preventDefault();

    setFormProcess({ ...formProcess, isLoading: true });

    if (validateEmail(email)) {
      setHintErrors({
        text: '',
        isError: false,
      });

      try {
        await passwordReset(email);

        setHintErrors({
          text: '',
          isError: true,
        });

        setFormProcess({
          ...formProcess,
          isLoading: false,
          isSuccessful: true,
        });
      } catch (error) {
        setFormProcess({ ...formProcess, isLoading: false });

        setHintErrors({
          text: error && error.response && error.response.data.message,
          isError: true,
        });
      }
    } else {
      setHintErrors({
        text: 'Please enter a valid email',
        isError: true,
      });

      setFormProcess({ ...formProcess, isLoading: false });
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const changeData = (e) =>
    setFormData({ ...formData, [e.currentTarget.name]: e.currentTarget.value });

  return (
    <div className={classes.iPopup}>
      <div className={classes.loginContainer}>
        <div className={'relative'}>
          <Close
            className={classes.close}
            onClick={() => dispatch(removePopup())}
          />

          {isSuccessful ? (
            <Successful />
          ) : (
            <ConfirmEmail
              classes={classes}
              email={email}
              changeData={changeData}
              emailFormSubmit={emailFormSubmit}
            />
          )}
        </div>

        {hintErrors.text && (
          <div className={classes.loginBox}>
            <Typography variant="body2" align="center" color="error">
              * {hintErrors.text}
            </Typography>
          </div>
        )}

        {isLoading && (
          <div className={classes.overlayLoader}>
            <CircularProgress color="primary" />
          </div>
        )}
      </div>

      <div className={classes.popupOverlay}></div>
    </div>
  );
};

export default ForgotPasswordPopup;
