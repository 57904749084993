import React, { useState } from 'react';
import GoogleLogin from '../../GoogleLogin';
import { Modal, Typography } from '@mui/material';

import {
  signupStart,
  signup,
  signupFail,
  socialSignUpAction,
} from '../../../../actions/auth';
import { openChangePopup, removePopup } from '../../../../actions/popup';
import LazyImage from '../../LazyImage';
import { Close } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../../hooks';

// import styles from './popups.module.css';

const SignupPopup = ({}) => {
  const loginDetails = useAppSelector((state) => state.auth);
  const popupData = useAppSelector((state) => state.popup);
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = formData;

  const responseGoogle = async (response) => {
    dispatch(socialSignUpAction(response, 'GOOGLE'));
  };

  const responseGoogleFail = async (response) => {
    console.error(response.error);
    dispatch(signupFail(null));
  };

  const parseErrorMessage = (error) => {
    if (error.message) {
      return error.message;
    }
    return 'Something went wrong';
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    dispatch(signupStart());

    const newDta = {
      email: email.toLowerCase(),
      password,
    };

    dispatch(signup(newDta));
  };

  const changeData = (e) =>
    setFormData({ ...formData, [e.currentTarget.name]: e.currentTarget.value });

  return (
    <Modal
      open
      onClose={() => dispatch(removePopup())}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div>
        <div className={`dataContainer`}>
          <div className={`relative`}>
            <span className={`close`}>
              <Close onClick={() => dispatch(removePopup())} />
            </span>
            <div className={`socialOuter`}>
              <div className={`header`}>
                <Typography variant="h5" align="center">
                  Join the tribe of learners!
                </Typography>
                <p>
                  Create an account to get connected to the Novatr tribe of
                  learners!
                </p>
              </div>
              <div>
                <form action="" onSubmit={(e) => formSubmit(e)}>
                  <input
                    type="email"
                    name="email"
                    className={`popinput`}
                    placeholder="Enter Email ID"
                    onChange={(e) => changeData(e)}
                    value={email}
                    required
                  />
                  <input
                    type="password"
                    name="password"
                    className={`popinput`}
                    placeholder="Enter Password"
                    onChange={(e) => changeData(e)}
                    value={password}
                    required
                  />
                  {popupData?.error?.message && (
                    <p className={`invalid`}>
                      {parseErrorMessage(popupData.error)}
                    </p>
                  )}
                  <button type="submit">
                    <p>Get started</p>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className={`signInbox`}>
            <div className={`socialOuter`}>
              <span className={`or`}>or sign up with one click </span>

              <div className={`socialLogin`}>
                <GoogleLogin
                  onSuccess={responseGoogle}
                  onError={responseGoogleFail}
                />
              </div>
              <div className={`footerContent`}>
                <p>
                  Already have an account? &nbsp;
                  <span
                    className={`pointer`}
                    onClick={() => dispatch(openChangePopup('LoginPopup'))}
                  >
                    <strong>Log in</strong>
                  </span>
                </p>
              </div>
            </div>
          </div>
          {loginDetails.loading && (
            <div className={`overlayLoader`}>
              <div>
                <LazyImage src="/images/icons/loader.svg" alt="" />
              </div>
            </div>
          )}
        </div>
        <style jsx>{`
          .overlayLoader {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(255, 255, 255, 0.85);
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .popinput {
            width: 100%;
            max-width: 379px;
            padding-left: 20px;
            height: 45px;
            border-radius: 10px;
            border: solid 1px #c7c6cb;
            background-color: #f5f5f5;
            margin-bottom: 19px;
          }

          .invalid {
            margin-bottom: 15px;
            color: #7f56d9;
          }

          .dataContainer {
            max-width: 420px;
            border-radius: 4px;
            box-shadow: 0 20px 40px 0 rgba(255, 90, 54, 0.16);
            border: solid 1px #c7c6cb;
            background-color: #ffffff;
            width: 85%;
            margin: auto;
            position: absolute;
            border-radius: 6px;
            overflow: hidden;
            min-height: 475px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .dataContainer > div .socialOuter {
            max-width: 340px;
            padding: 25px 30px;
            margin: auto;
          }
          .dataContainer > div .header > * {
            text-align: center;
          }
          .header h3 {
            padding: 0 15px;
          }

          .dataContainer > div form input[type='submit'],
          .dataContainer > div form input[type='button'] {
            width: 100%;
            border-radius: 4px;
            color: #fff;
            border-color: transparent;
            font-size: 15px;
            padding: 13px 0;
          }
          .dataContainer > div form input[type='email'] {
            background-image: url('https://lh3.googleusercontent.com/proxy/x1hePiPzmy4urS7dcsN2u4vTQuCoMuww7uRMqPb_bhR9g1ihE4a8A1nRy_09SOg0zLqmyPUP4JdIuvonunEAAn2hnZq7FZGidpw2zwzHuFRIEFF6msoNig');
            background-size: 19px;
            background-position: right 11px bottom 13px;
            background-repeat: no-repeat;
          }
          .dataContainer > div form button {
            background-color: #7f56d9;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 0;
            min-height: 50px;
            border-radius: 10px;
          }
          .dataContainer > div form button.loadingActive {
            background: #7f56d9d1;
          }

          .dataContainer > div form button.loadingActive .loadingIcn {
            display: block;
          }

          .dataContainer > div form button .loadingIcn {
            width: 35px;
            margin-right: 12px;
            display: none;
          }
          .dataContainer > div form button.loadingActive {
            background: #7f56d9d1;
          }

          .dataContainer > div form button.loadingActive .loadingIcn {
            display: block;
          }
          .dataContainer > div form button p {
            color: #fff;
          }
          .dataContainer .startedBtn {
            background: #7f56d9;
            display: flex;
          }
          .dataContainer .startedBtn img {
            width: 50px;
          }
          .header p {
            margin: 11px auto 23px;
            width: 100%;
            color: #000;
          }

          .header h3 {
            margin-top: 8px;
            color: #000;
          }

          .signInbox {
            text-align: center;
          }

          .dataContainer input,
          .dataContainer select,
          .dataContainer option {
            outline: none;
          }
          span.or {
            display: block;
            position: relative;
          }
          span.or:before,
          span.or:after {
            content: ' ';
            display: block;
            width: 40px;
            height: 1px;
            position: absolute;
            background: grey;
          }
          span.or:before {
            left: 0;
            top: 9px;
          }

          span.or:after {
            right: 0;
            top: 9px;
          }
          .close {
            position: absolute;
            right: 20px;
            top: 20px;
            color: darkgrey;
            width: 18px;
            height: 18px;
            cursor: pointer;
          }

          .relative {
            position: relative;
          }
          .dataContainer ::placeholder {
            color: #9f9ea3;
          }

          .socialLogin {
            display: grid;
            /* grid-template-columns: repeat(1, 78px); */
            justify-content: center;
            /* grid-column-gap: 5px; */
            margin: 18px 0 25px;
          }

          .socialLogin button span {
            color: #222222;
          }

          .socialItem {
            border-radius: 4px;
            border: solid 0.5px #000000;
            background-color: #ffffff;
            cursor: pointer;
            /* width: 75px; */
            height: 75px;
            display: grid;
            transition: 0.3s;
            align-items: center;
            position: relative;
          }
          .waiting {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: grid;
            align-items: center;
            justify-content: center;
            background: rgba(57, 54, 79, 0.8);
            display: none;
          }
          .socialItem span {
            font-size: 11px;
            margin-top: 7px;
            transition: 0.3s;
            display: block;
          }
          .socialItem img {
            width: 28px;
            display: block;
            margin: auto;
          }

          .socialItem:hover {
            box-shadow: 0 3px 6px 0 rgba(255, 90, 54, 0.16);
            border: solid 0.5px #7f56d9;
            background-color: #ffffff;
          }
          .socialItem:hover span {
            color: #7f56d9;
          }
          .footerContent * {
            text-align: center;
          }

          .footerContent span {
            text-decoration: underline;
            cursor: pointer;
          }

          .forgotPass {
            display: block;
            text-align: right;
            margin-top: 10px;
            height: 20px;
            display: grid;
            justify-content: end;
            align-items: center;
            font-size: 13px;
            text-decoration: underline;
            cursor: pointer;
            opacity: 0.8;
            transition: all 0.3s;
          }

          .forgotPass:hover {
            opacity: 1;
            font-size: 13.1px;
          }

          @media (min-width: 568px) {
            .dataContainer > div .socialOuter {
              /* max-width: 380px; */
              padding: 30px 0;
              margin: auto;
            }
            .socialLogin {
              /* display: grid; */
              /* grid-template-columns: repeat(1, 116px); */
              margin: 28px 0;
              /* grid-column-gap: 12px; */
            }

            .header p {
              margin: 17px auto 45px;
            }
            .invalid {
              margin-bottom: 29px;
              font-size: 14px;
              color: #7f56d9;
            }
            .socialItem {
              /* width: 115px; */
              height: 115px;
            }
            .socialItem img {
              width: 55px;
            }
            .socialItem span {
              font-size: 14px;
              margin-top: 11px;
            }

            .dataContainer > div form input[type='submit'],
            .dataContainer > div form input[type='button'] {
              font-size: 18px;
              padding: 20px 0;
            }
            span.or:before,
            span.or:after {
              width: 85px;
              height: 1px;
            }
          }

          @media (min-width: 1200px) {
            .dataContainer > div .socialOuter {
              padding: 20px 0;
            }

            .header h3 {
              padding: 0;
            }
            .invalid {
              font-size: 16px;
            }
          }
        `}</style>
      </div>
    </Modal>
  );
};

export default SignupPopup;
