import React, { Fragment } from 'react';
import LoginPopup from './LoginPopup';
import SignupPopup from './SignupPopup';
import ForgotPasswordPopup from '../ForgotPasswordPopup';
import { useAppSelector } from '../../../../hooks';

const Index = ({}) => {
  const popup = useAppSelector((state) => state.popup);
  return (
    <Fragment>
      {popup.activePopup === 'LoginPopup' && <LoginPopup />}
      {popup.activePopup === 'SignupPopup' && <SignupPopup />}
      {popup.activePopup === 'ForgotPasswordPopup' && <ForgotPasswordPopup />}
    </Fragment>
  );
};

export default Index;
